import React, { Component, useState } from 'react'
import InputMask from 'react-input-mask'

// Components Globals
import Header from 'components/header'
import Navigation from 'components/navigation-pages'
import Footer from 'components/footer/main'
import SEO from 'components/seo'

import IbgeStateCity from 'components/forms/ibge-state-city'

import { getRdTrackId, getTrackingSource } from 'utils/cookies'

// Style this component
import {
  BannerSobre,
  InfoContato,
  FormContainer,
  FormContato,
  FormGroup,
  TwoInput,
  IconContainer,
  RedesSociais,
} from '../../Style/contato/style'
import { Container, TitleBanner, SubtitleBanner } from '../../Style/global'

//images
import IconContato from 'images/iconContato.svg'
import IconPhone from 'images/icon-phone.svg'
import IconClock from 'images/icon-clock.svg'
import IconPin from 'images/icon-pin.svg'
import IconFacebook from 'images/icon-facebook.svg'
import IconInstagram from 'images/icon-instagram.svg'
import IconInovafarma from 'images/icon-inovafarma.svg'
import IconYoutube from 'images/icon-youtube.svg'

import BannerContato from 'images/banners/banner-contato.svg'
import SucessoContato from 'images/successo-form.svg'

// Sass Global
import 'sass/main.scss'

import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'

const CONTATO_MUTATION = gql`
  mutation EnviarContato(
    $nomeCompleto: String!
    $email: String!
    $nomeEmpresa: String
    $estado: String!
    $cidade: String!
    $assunto: String!
    $telefone: String!
    $horarioContato: String
    $mensagem: String!
    $clientTrackingId: String
    $trafficSource: String
  ) {
    createContato(
      input: {
        data: {
          nomeCompleto: $nomeCompleto
          email: $email
          nomeEmpresa: $nomeEmpresa
          estado: $estado
          cidade: $cidade
          assunto: $assunto
          telefone: $telefone
          horarioContato: $horarioContato
          mensagem: $mensagem
          clientTrackingId: $clientTrackingId
          trafficSource: $trafficSource
        }
      }
    ) {
      contato {
        createdAt
      }
    }
  }
`

const Contato = () => {
  // These state variables will hold the form values when it is filled out
  const [nomeCompletoValue, setNomeCompletoValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [nomeEmpresaValue, setNomeEmpresaValue] = useState('')
  const [estadoValue, setEstadoValue] = useState('')
  const [cidadeValue, setCidadeValue] = useState('')
  const [assuntoValue, setAssuntoValue] = useState('')
  const [telefoneValue, setTelefoneValue] = useState('')
  const [horarioContatoValue, setHorarioContatoValue] = useState('')
  const [mensagemValue, setMensagemValue] = useState('')

  const handleStateChange = value => {
    setEstadoValue(value)
  }
  const handleCityChange = value => {
    setCidadeValue(value)
  }

  const handleSubmit = createContato => event => {
    // when the form is submitted, we will run the signUp function passed down
    // the Mutation component, and pass it the form values from the state variables
    event.preventDefault()
    createContato({
      variables: {
        nomeCompleto: nomeCompletoValue,
        email: emailValue,
        nomeEmpresa: nomeEmpresaValue,
        estado: estadoValue,
        cidade: cidadeValue,
        assunto: assuntoValue,
        telefone: telefoneValue,
        horarioContato: horarioContatoValue,
        mensagem: mensagemValue,
        clientTrackingId: getRdTrackId(),
        trafficSource: getTrackingSource(),
      },
    })
  }

  return (
    <FormContainer>
      <Mutation mutation={CONTATO_MUTATION}>
        {(createContato, { loading, error, data }) => (
          <React.Fragment>
            {data ? (
              <div className="sucesso-form">
                <div className="resposta">
                  <img src={SucessoContato} alt="Icone de sucesso" />
                  <h3>Mensagem enviada com sucesso</h3>
                  <p>Responderemos ou entraremos em contato em no máximo 1 dia útil.</p>
                </div>
              </div>
            ) : (
              <FormContato id="form_contato" name="form_contato" onSubmit={handleSubmit(createContato)}>
                <div className="title">
                  <div className="icon">
                    <img src={IconContato} alt="Icone contato" />
                  </div>
                  <h3>Envie-nos uma mensagem</h3>
                </div>
                <div className="form-area">
                  <FormGroup>
                    <label htmlFor="nome_completo">Nome completo*</label>
                    <input
                      type="text"
                      name="nome_completo"
                      id="nome_completo"
                      required
                      value={nomeCompletoValue}
                      onChange={event => {
                        setNomeCompletoValue(event.target.value)
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="email">E-mail*</label>
                    <input
                      type="email"
                      name="email"
                      required
                      value={emailValue}
                      onChange={event => {
                        setEmailValue(event.target.value)
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="nome_empresa">Nome da empresa*</label>
                    <input
                      type="text"
                      name="nome_empresa"
                      id="nome_empresa"
                      required
                      value={nomeEmpresaValue}
                      onChange={event => {
                        setNomeEmpresaValue(event.target.value)
                      }}
                    />
                  </FormGroup>
                  <TwoInput>
                    <IbgeStateCity onStateChange={handleStateChange} onCityChange={handleCityChange} />
                  </TwoInput>
                  <FormGroup>
                    <label htmlFor="assunto">Assunto*</label>
                    <select
                      name="assunto"
                      id="assunto"
                      required
                      value={assuntoValue}
                      onChange={event => {
                        setAssuntoValue(event.target.value)
                      }}
                    >
                      <option disabled value="" hidden>
                        Selecione o assunto
                      </option>
                      <option value="Quero ser um representante">Quero ser um representante</option>
                      <option value="Dúvidas">Dúvidas</option>
                      <option value="Solicitar Demonstração">Solicitar Demonstração</option>
                      <option value="Quero o inovafarma para minha farmácia">Quero o inovafarma para minha farmácia</option>
                    </select>
                  </FormGroup>
                  <TwoInput>
                    <FormGroup>
                      <label htmlFor="telefone">Telefone*</label>
                      <InputMask
                        type="text"
                        name="telefone"
                        id="telefone"
                        required
                        mask="(99)99999-9999"
                        value={telefoneValue}
                        onChange={event => {
                          setTelefoneValue(event.target.value)
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label htmlFor="melhor_horario_contato">Melhor horário para contato*</label>
                      <input
                        type="text"
                        name="melhor_horario_contato"
                        id="melhor_horario_contato"
                        required
                        value={horarioContatoValue}
                        onChange={event => {
                          setHorarioContatoValue(event.target.value)
                        }}
                      />
                    </FormGroup>
                  </TwoInput>
                  <FormGroup>
                    <label htmlFor="mensagem">Colocar mensagem*</label>
                    <textarea
                      name="mensagem"
                      id="mensagem"
                      required
                      value={mensagemValue}
                      onChange={event => {
                        setMensagemValue(event.target.value)
                      }}
                    />
                  </FormGroup>
                </div>
                <span className="aviso">Responderemos ou entraremos em contato em no máximo 1 dia útil.</span>
                <button type="submit">Enviar</button>
              </FormContato>
            )}
          </React.Fragment>
        )}
      </Mutation>
    </FormContainer>
  )
}

class PageContato extends Component {
  render() {
    return (
      <div className="main">
        <SEO title="Contato" />

        <Header />

        <Navigation from={this.props.location.pathname} />

        <BannerSobre bg={BannerContato}>
          <div className="container">
            <div>
              <TitleBanner>Contato</TitleBanner>
              <SubtitleBanner>Fale com um dos nossos consultores</SubtitleBanner>
            </div>
            <Contato />
          </div>
        </BannerSobre>

        <InfoContato>
          <Container>
            <h3 className="title-contato">
              Ficou com alguma dúvida?
              <br />
              Entre em contato pelos canais oficiais.
            </h3>
            <div className="icons">
              <IconContainer>
                <div className="icon">
                  <img src={IconPhone} alt="Icone telefone" />
                </div>
                <span className="title">+55 (17) 3624-2110</span>
              </IconContainer>
              <IconContainer>
                <div className="icon">
                  <img src={IconClock} alt="Icone relógio" />
                </div>
                <div className="text">
                  <span className="title">Horário de atendimento:</span>
                  <span className="sub">Segunda à Sexta-feira, das 08h às 18h</span>
                </div>
              </IconContainer>
              <IconContainer>
                <div className="icon">
                  <img src={IconPin} alt="Icone localização" />
                </div>
                <div className="text">
                  <span className="title">Precisão Sistemas</span>
                  <span className="sub">Rua Dez, 1688, Jardim Maria Paula Jales/SP - 15704-100</span>
                </div>
              </IconContainer>
            </div>
            <RedesSociais>
              <h4>Redes sociais da Inova Farma</h4>
              <div className="social-icons">
                <a href="https://www.facebook.com/SistemaInovaFarma/" target="_blank" rel="noopener noreferrer">
                  <img src={IconFacebook} alt="Ícone Facebook" />
                </a>
                <a href="https://www.instagram.com/sistemainovafarma/" target="_blank" rel="noopener noreferrer">
                  <img src={IconInstagram} alt="Ícone Instagram" />
                </a>
                <a href="https://www.inovafarma.com.br/blog/" target="_blank" rel="noopener noreferrer">
                  <img src={IconInovafarma} alt="Ícone Inovafarma" />
                </a>
                <a href="https://www.youtube.com/channel/UCkMlMaX65X_tMDHR8luXagQ" target="_blank" rel="noopener noreferrer">
                  <img src={IconYoutube} alt="Ícone Youtube" />
                </a>
              </div>
            </RedesSociais>
          </Container>
        </InfoContato>

        <Footer padding_maior={false} />
      </div>
    )
  }
}

export default PageContato
